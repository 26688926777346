import { useState, useEffect } from 'react';
import i18n from '../i18n/i18n';

// Hooks
import { useTheme } from '../hooks/useTheme';

// Components imports
import HeaderBar from '../components/HeaderBar';
import SearchFilterBar from '../components/Search&FilterBar';
import SessionsSkeleton from '../components/SessionsSkeleton';
import Session from '../components/Session';
import DrawerCreateSession from '../components/Drawer/DrawerCreateSession';

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

// Helpers
import { getSessions } from '../helpers/api/callSession';
import { handleScroll } from '../helpers/functions/handleScroll';
import { sortAscendingSession } from '../helpers/functions/sortSession';
import { useUserStore } from '../store/UserStore';

// Types
import { SessionData } from '../types/sessionData';

const Sessions = () => {
    const [scrollPosition, setScrollPosition] = useState<'top' | 'middle' | 'bottom'>('top');
    const [sessionList, setSessionList] = useState<Array<SessionData>>([]);
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();
    const { user } = useUserStore();
    const smallScreen = useMediaQuery('(max-height:700px)');

    useEffect(() => {
        const fetchSessions = async () => {
            try {
                setIsLoading(true);
                const sessions = await getSessions(user.id as string);
                const sortedSessions = sortAscendingSession(sessions);
                setSessionList(sortedSessions);
            } catch (error) {
                console.error('Error fetching sessions:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSessions();
    }, []);

    if (isLoading) return <SessionsSkeleton />;

    if (sessionList.length === 0)
        return (
            <Box sx={{ height: '100vh', backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
                <HeaderBar />
                {/* <SearchFilterBar /> */}
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '0 auto', width: '75%', height: '70vh', marginTop: '1em' }}>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontSize: '1em',
                            color: theme.palette.text.primary
                        }}
                    >
                        {i18n.t('Sessions.noSessions')}
                    </Typography>
                    <DrawerCreateSession buttonStatus="fixed" scrollPosition="top" />
                </Box>
            </Box>
        );

    return (
        <Box sx={{ height: '100vh', backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
            <HeaderBar />
            {/* <SearchFilterBar /> */}
            <Box
                className="list-container"
                onScroll={(event) => handleScroll(event, setScrollPosition)}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    marginTop: '1em',
                    height: smallScreen ? 'calc(100vh - 6vh - 6vh - 3em)' : 'calc(100vh - 6vh - 6vh - 1.3em)',
                    overflowY: 'auto'
                }}
            >
                {sessionList.map((session, index) => (
                    <Session key={session.id} session={session} nbSessions={sessionList.length} customStyle={index < sessionList.length - 1 ? true : false} />
                ))}
            </Box>
            <DrawerCreateSession buttonStatus="floating" scrollPosition={scrollPosition} />
        </Box>
    );
};

export default Sessions;
