import { useEffect, useState } from 'react';
import { useTheme } from '../hooks/useTheme';
import i18n from '../i18n/i18n';

// Components
import HeaderBar from '../components/HeaderBar';
import ContentInfo from '../components/ContentInfo';
import SingleTrainee from '../components/SingleTrainee';
import InternsSkeleton from '../components/InternsSkeleton';
import QRCode from '../components/Drawer/DrawerQRCode';
import DrawerAddIntern from '../components/Drawer/DrawerAddIntern';

// MUI imports
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import GestureOutlinedIcon from '@mui/icons-material/GestureOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';

// Types
import { SlotData } from '../types/slotData';
import { InternData } from '../types/internData';

// Helpers
import { getInternsSigned } from '../helpers/api/callAttendance';
import { handleScroll } from '../helpers/functions/handleScroll';
import { useSessionStore } from '../store/SessionStore';
import { getCurrentSlot } from '../helpers/functions/getCurrentSlot';
import { getInternById } from '../helpers/api/callIntern';

const Interns = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [scrollPosition, setScrollPosition] = useState<'top' | 'middle' | 'bottom'>('top');
    const [trainees, setTrainees] = useState<Array<InternData>>([]);
    const [errorSlotMessage, setErrorSlotMessage] = useState<string>('');
    const { session } = useSessionStore();
    const theme = useTheme();
    const smallScreen = useMediaQuery('(max-height:700px)');

    const [todaySlot, setTodaySlot] = useState<SlotData>({
        id: '',
        date: '',
        startDate: '',
        endDate: '',
        sessionId: ''
    });

    const linkSession = process.env.REACT_APP_QW_URL + '/evaluation/' + session.sessionToken;
    const [internsSigned, setInternsSigned] = useState<number>(0);
    const [colorCountSigned, setColorCountSigned] = useState<string>('#5BB3D9');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const currentSlot = await getCurrentSlot(session.id);

                if (!currentSlot) {
                    setIsLoading(false);
                    setErrorSlotMessage(i18n.t('Interns.noSlot'));
                    return;
                }

                setTodaySlot(currentSlot);

                const internsSigned = await getInternsSigned(session.id, currentSlot.id);
                setInternsSigned(internsSigned);

                if (internsSigned === Number(session.trainees.length + 1)) {
                    setColorCountSigned('#36D476');
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des données:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [session.id]);

    useEffect(() => {
        const fetchTraineesData = async () => {
            try {
                const internsData: (InternData | null)[] = await Promise.all(
                    session.trainees.map(async (trainee: string) => {
                        const internData = await getInternById(trainee);
                        return internData;
                    })
                );

                if (internsData.length === 0) return;

                const filteredInternsData = internsData.filter((intern) => intern !== null) as InternData[];
                setTrainees(filteredInternsData);
            } catch (error) {
                console.error('Erreur lors de la récupération des stagiaires:', error);
            }
        };

        fetchTraineesData();
    }, [session.trainees]);

    if (isLoading) {
        return <InternsSkeleton nbInterns={session.trainees.length} />;
    }

    return (
        <Box sx={{ width: '100%', height: '100vh', alignItems: 'center', backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
            <HeaderBar />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 auto',
                    width: '100%'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '92.5%',
                        margin: '0 auto'
                    }}
                >
                    <ContentInfo margin="0.5em 0 0.3em 0" padding="0.5em 0">
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '92.5%',
                                minHeight: '10vh'
                            }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: 'auto', height: '100%' }}>
                                <Typography sx={{ padding: '1.3em 0', fontSize: '1.2em', color: theme.palette.secondary.main }}>{session.trainingLabel}</Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: 'auto',
                                        color: theme.palette.text.primary
                                    }}
                                >
                                    {errorSlotMessage ? (
                                        <Typography sx={{ fontSize: '0.8em', textTransform: 'capitalize' }}>{errorSlotMessage}</Typography>
                                    ) : (
                                        <>
                                            <Typography sx={{ fontSize: '0.8em', textTransform: 'capitalize' }}>{todaySlot.date} :</Typography>
                                            <Typography sx={{ fontSize: '0.8em', marginLeft: '0.5em', fontWeight: '600' }}>
                                                {todaySlot.startDate} - {todaySlot.endDate}
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: 'auto', height: '100%' }}>
                                <QRCode link={linkSession} token={session.sessionToken} />
                                <Typography sx={{ fontWeight: '600', color: theme.palette.text.custom }}>{session.sessionToken}</Typography>
                            </Box>
                        </Box>
                    </ContentInfo>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '6vh', marginBottom: '0.5em' }}>
                        {/* <Box sx={{ display: 'flex', flexDirection: 'row', color: '#FFB664' }}>
                            <PersonOffOutlinedIcon sx={{ marginRight: '0.2em', fontSize: '1.75em' }} />
                            <Typography sx={{ fontSize: '1.3em' }}>0</Typography>
                        </Box> */}
                        <Box sx={{ display: 'flex', flexDirection: 'row', color: colorCountSigned }}>
                            <GestureOutlinedIcon sx={{ marginRight: '0.2em', fontSize: '1.75em' }} />
                            <Typography sx={{ fontSize: '1.3em' }}>
                                {internsSigned} / {session.trainees.length + 1}
                            </Typography>
                        </Box>
                        {/* <Box sx={{ display: 'flex', flexDirection: 'row', color: '#5BB3D9' }}>
                            <InventoryOutlinedIcon sx={{ marginRight: '0.2em', fontSize: '1.75em' }} />
                            <Typography sx={{ fontSize: '1.3em' }}>0 / {session.trainees.length + 1}</Typography>
                        </Box> */}
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                    <Typography sx={{ fontWeight: '600', fontSize: '1.2em', marginBottom: '0.5em', marginLeft: '1em', color: theme.palette.primary.main }}>{i18n.t('Interns.title')}</Typography>
                    <>
                        {session.trainees.length !== 0 ? (
                            <>
                                <Box
                                    className="list-container"
                                    onScroll={(event) => handleScroll(event, setScrollPosition)}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        width: '96%',
                                        height: smallScreen ? 'calc(100vh - 6vh - 6vh - 14.25em)' : 'calc(100vh - 6vh - 6vh - 13.5em)',
                                        overflow: 'auto',
                                        marginLeft: 'auto',
                                        whiteSpace: 'pre'
                                    }}
                                >
                                    {trainees.map((trainee: InternData, index: number) => (
                                        <SingleTrainee
                                            key={trainee.id}
                                            trainee={trainee}
                                            todaySlot={todaySlot}
                                            customStyle={index < trainees.length - 1 ? true : false}
                                            numberTrainees={trainees.length}
                                        />
                                    ))}
                                </Box>
                                <DrawerAddIntern buttonStatus="floating" scrollPosition={scrollPosition} traineesId={session.trainees} sessionId={session.id} />
                            </>
                        ) : (
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '0 auto', width: '75%', height: '50vh' }}>
                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        fontSize: '1.2em',
                                        color: theme.palette.text.primary
                                    }}
                                >
                                    {i18n.t('Interns.noInterns')}
                                </Typography>
                                <DrawerAddIntern buttonStatus="fixed" scrollPosition={scrollPosition} traineesId={session.trainees} sessionId={session.id} />
                            </Box>
                        )}
                    </>
                </Box>
            </Box>
        </Box>
    );
};

export default Interns;
