import React, { useState } from 'react';
import i18n from '../../i18n/i18n';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import 'dayjs/locale/de';
import slugify from 'react-slugify';
import { useNavigate } from 'react-router-dom';

// Components
import TitleContentInfo from '../TitleContentInfo';
import SignEvalTextField from '../SignEvalTextField';
import SignEvalButton from '../SignEvalButton';
import FloatingButton from '../Button/FloatingButton';

// MUI imports
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ArticleIcon from '@mui/icons-material/Article';
import EventIcon from '@mui/icons-material/Event';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Autocomplete } from '@mui/material';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

// Helper
import { useTheme } from '../../hooks/useTheme';
import { useUserStore } from '../../store/UserStore';
import { useSessionStore } from '../../store/SessionStore';
import { getTraining, createTraining } from '../../helpers/api/callTraining';
import { getSessionByName, createSession } from '../../helpers/api/callSession';
import { TrainingData } from '../../types/trainingData';
import { formatTraining } from '../../helpers/functions/formatTraining';

interface DrawerCreateSessionProps {
    buttonStatus: 'floating' | 'fixed';
    scrollPosition: 'top' | 'middle' | 'bottom';
}

type FormValues = {
    sessionName: string;
    training: string;
    beginDate: Dayjs;
    endDate: Dayjs;
};

const DrawerCreateSession = ({ scrollPosition, buttonStatus }: DrawerCreateSessionProps) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [trainings, setTrainings] = useState<TrainingData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const theme = useTheme();
    const navigate = useNavigate();
    const { user } = useUserStore();
    const { setSession } = useSessionStore();

    const handleLanguageChange = (language: string) => {
        switch (language) {
            case 'fr-FR':
                return 'fr';
            case 'en-EN':
                return 'en';
            case 'de-DE':
                return 'de';
            default:
                return 'en';
        }
    };

    const fetchTrainings = async () => {
        const trainings = await getTraining();
        const formattedTrainings = formatTraining(trainings);
        setTrainings(formattedTrainings);
    };

    const {
        register,
        handleSubmit,
        control,
        resetField,
        formState: { errors },
        clearErrors,
        setValue
    } = useForm<FormValues>();

    // Check if endDate is before beginDate else set error
    if (control._getWatch('beginDate') && control._getWatch('endDate') && control._getWatch('endDate').isBefore(control._getWatch('beginDate'))) {
        setError(i18n.t('CreateSession.errors.invalidDate'));
    }

    const handleOpenDrawer = () => {
        setIsDrawerOpen(true);
        fetchTrainings();
    };

    const handleCloseDrawer = () => {
        resetField('sessionName');
        resetField('training');
        resetField('beginDate');
        resetField('endDate');
        setIsDrawerOpen(false);
    };

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        const selectedTraining = trainings.find((t) => t.label === data.training);
        const trainingCode = selectedTraining?.trainingCode || slugify(data.training);
        const beginDate = data.beginDate.format('YYYY-MM-DDTHH:mm:ssZ');
        const endDate = data.endDate.format('YYYY-MM-DDTHH:mm:ssZ');
        const trainer = String(user?.id);

        try {
            setIsLoading(true);

            let trainingId: string;

            if (!selectedTraining) {
                const newTraining: TrainingData = {
                    label: data.training,
                    trainingCode
                };
                const createdTraining = await createTraining(newTraining);
                trainingId = String(createdTraining.id);
            } else {
                trainingId = String(selectedTraining.id);
            }

            const { status } = await createSession(data.sessionName, trainingId, beginDate, endDate, trainer);

            if (status === 409) {
                setError(i18n.t('CreateSession.errors.sessionExists'));
                return;
            }

            const sessionId = await getSessionByName(data.sessionName);

            if (!sessionId) {
                console.error('Error getting session ID');
                return;
            }

            const sessionData = {
                id: sessionId,
                trainingLabel: data.training,
                sessionToken: '',
                sessionName: data.sessionName,
                sessionBegin: beginDate,
                sessionEnd: endDate,
                trainerId: trainer,
                trainerName: '',
                trainingId: trainingId,
                trainees: [],
                slotsId: []
            };

            setSession(sessionData);
            handleCloseDrawer();
            navigate(`/sessions/${sessionId}`);
        } catch (error) {
            console.error('Error creating training or session:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {buttonStatus === 'floating' ? (
                <FloatingButton isExtended={scrollPosition === 'top'} onClick={handleOpenDrawer} label={i18n.t('CreateSession.title')} icon={<AddCircleOutlineIcon />} />
            ) : (
                <Button variant="contained" sx={{ height: '3.5em', marginTop: '1em', color: 'white', backgroundColor: '#1A82AF', borderRadius: '0.85em' }} onClick={handleOpenDrawer}>
                    <AddCircleOutlineIcon />
                    <Typography variant="button" sx={{ marginLeft: '0.5em', fontSize: '1.1em', textTransform: 'none' }}>
                        {i18n.t('CreateSession.title')}
                    </Typography>
                </Button>
            )}
            <Drawer
                anchor="bottom"
                PaperProps={{
                    sx: {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: '15px 15px 0 0',
                        paddingBottom: '1em',
                        width: '100%',
                        height: 'auto',
                        backgroundColor: theme.palette.background.default,
                        backgroundImage: 'none'
                    }
                }}
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
            >
                <IconButton onClick={handleCloseDrawer} sx={{ alignSelf: 'flex-end', margin: '0.5em' }}>
                    <CloseIcon sx={{ color: theme.palette.text.custom, fontSize: '1.05em' }} />
                </IconButton>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '87%', color: '#000', marginTop: '-1em' }}>
                    <TitleContentInfo title={i18n.t('CreateSession.title')} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: '0.5em auto 0 auto', width: '100%', height: '19em' }}>
                        <SignEvalTextField
                            icon={<ArticleIcon />}
                            iconPosition="end"
                            registerProps={{
                                ...register('sessionName', {
                                    required: true,
                                    pattern: {
                                        value: /^[a-zA-ZÀ-ÿ\s.'-]+$/,
                                        message: i18n.t('CreateSession.error.sessionName')
                                    }
                                }),
                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                    clearErrors('sessionName');
                                    setError(null);
                                    setValue('sessionName', e.target.value);
                                }
                            }}
                            id="sessionName"
                            label={i18n.t('CreateSession.inputs.sessionName') + ' *'}
                            error={!!errors.sessionName}
                            type="text"
                        />
                        <Controller
                            name="training"
                            defaultValue=""
                            control={control}
                            rules={{ required: i18n.t('CreateSession.error.trainingName') }}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={trainings.map((training) => training.label)}
                                    freeSolo
                                    value={field.value}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    noOptionsText={i18n.t('CreateSession.errors.noTrainings')}
                                    onChange={(event, newValue) => {
                                        if (newValue && !trainings.some((t) => t.label === newValue)) {
                                            setValue('training', newValue);
                                            clearErrors('training');
                                        } else {
                                            field.onChange(newValue || '');
                                            clearErrors('training');
                                        }
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        setValue('training', newInputValue);
                                        clearErrors('training');
                                    }}
                                    renderInput={(params) => (
                                        <SignEvalTextField
                                            {...params}
                                            label={i18n.t('CreateSession.inputs.trainingName') + ' *'}
                                            InputProps={{
                                                ...params.InputProps,
                                                sx: {
                                                    maxHeight: '3em',
                                                    borderRadius: '10px',
                                                    color: theme.palette.text.primary
                                                }
                                            }}
                                            InputLabelProps={{
                                                sx: { fontSize: '0.85em', color: theme.palette.text.primary }
                                            }}
                                            error={!!errors.training}
                                        />
                                    )}
                                    ListboxProps={{
                                        sx: {
                                            width: '100%',
                                            backgroundColor: theme.palette.background.paper,
                                            color: theme.palette.text.primary,
                                            borderRadius: '10px',
                                            marginTop: '0.5em',
                                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                                            maxHeight: '9em',
                                            overflow: 'auto',
                                            listStyle: 'none',
                                            position: 'absolute'
                                        }
                                    }}
                                />
                            )}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={handleLanguageChange(i18n.language)}>
                            <Controller
                                control={control}
                                name="beginDate"
                                defaultValue={undefined}
                                rules={{ required: i18n.t('CreateSession.error.beginDate') }}
                                render={({ field }) => (
                                    <DatePicker
                                        label={i18n.t('CreateSession.inputs.sessionBeginDate') + ' *'}
                                        value={field.value || null}
                                        onChange={(date) => field.onChange(date)}
                                        slotProps={{
                                            textField: {
                                                InputProps: {
                                                    endAdornment: <EventIcon />,
                                                    sx: { minHeight: '3em', borderRadius: '10px' }
                                                },
                                                inputRef: field.ref,
                                                error: !!errors.beginDate
                                            },
                                            toolbar: {
                                                sx: { backgroundColor: theme.palette.text.custom, color: theme.palette.text.secondary }
                                            },
                                            layout: {
                                                sx: {
                                                    ' .MuiDayCalendar-weekDayLabel': {
                                                        color: theme.palette.text.custom
                                                    },
                                                    '& .MuiPickersDay-root': {
                                                        '&:focus': {
                                                            backgroundColor: theme.palette.text.custom
                                                        },
                                                        '&:active': {
                                                            backgroundColor: theme.palette.text.custom
                                                        },
                                                        '&.Mui-selected': {
                                                            backgroundColor: theme.palette.text.custom
                                                        },
                                                        '&.MuiPickersDay-daySelected': {
                                                            backgroundColor: theme.palette.text.custom
                                                        }
                                                    },
                                                    color: theme.palette.text.primary,
                                                    backgroundColor: theme.palette.background.default
                                                }
                                            }
                                        }}
                                        localeText={{
                                            toolbarTitle: i18n.t('CreateSession.inputs.sessionBeginDate'),
                                            cancelButtonLabel: i18n.t('CreateSession.button.cancel'),
                                            okButtonLabel: i18n.t('CreateSession.button.ok')
                                        }}
                                        slots={{
                                            textField: SignEvalTextField
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="endDate"
                                defaultValue={undefined}
                                rules={{
                                    required: i18n.t('CreateSession.error.endDate'),
                                    validate: (value) => {
                                        const beginDate = control._getWatch('beginDate');
                                        if (!beginDate) {
                                            return true;
                                        }
                                        if (value && value.isBefore(beginDate)) {
                                            return i18n.t('CreateSession.error.invalidEndDate');
                                        }
                                        return true;
                                    }
                                }}
                                render={({ field }) => (
                                    <DatePicker
                                        label={i18n.t('CreateSession.inputs.sessionEndDate') + ' *'}
                                        value={field.value || null}
                                        onChange={(date) => field.onChange(date)}
                                        minDate={control._getWatch('beginDate') || null}
                                        slotProps={{
                                            textField: {
                                                InputProps: {
                                                    endAdornment: <EventIcon />,
                                                    sx: { minHeight: '3em', borderRadius: '10px' }
                                                },
                                                inputRef: field.ref,
                                                error: !!errors.beginDate
                                            },
                                            toolbar: {
                                                sx: { backgroundColor: theme.palette.text.custom, color: theme.palette.text.secondary }
                                            },
                                            layout: {
                                                sx: {
                                                    ' .MuiDayCalendar-weekDayLabel': {
                                                        color: theme.palette.text.custom
                                                    },
                                                    '& .MuiPickersDay-today': {
                                                        border: '1px solid ${theme.palette.text.custom}'
                                                    },
                                                    '& .MuiPickersDay-root': {
                                                        '&:focus': {
                                                            backgroundColor: theme.palette.text.custom
                                                        },
                                                        '&:active': {
                                                            backgroundColor: theme.palette.text.custom
                                                        },
                                                        '&.Mui-selected': {
                                                            backgroundColor: theme.palette.text.custom
                                                        },
                                                        '&.MuiPickersDay-daySelected': {
                                                            backgroundColor: theme.palette.text.custom
                                                        }
                                                    },
                                                    color: theme.palette.text.primary,
                                                    backgroundColor: theme.palette.background.default
                                                }
                                            }
                                        }}
                                        localeText={{
                                            toolbarTitle: i18n.t('CreateSession.inputs.sessionBeginDate'),
                                            cancelButtonLabel: i18n.t('CreateSession.button.cancel'),
                                            okButtonLabel: i18n.t('CreateSession.button.ok')
                                        }}
                                        slots={{
                                            textField: SignEvalTextField
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <Typography sx={{ textAlign: 'center', fontSize: '0.7em', color: theme.palette.error.main }}>{error}</Typography>
                        <Typography sx={{ fontSize: '0.7em', marginRight: 'auto', color: theme.palette.text.primary }}>{i18n.t('Login.RequiredFields') + '*'}</Typography>
                    </Box>
                    {isLoading ? (
                        <CircularProgress sx={{ margin: '1.5em auto 1em auto', height: '3em', color: theme.palette.text.custom }} />
                    ) : (
                        <SignEvalButton sx={{ margin: '1.5em 0 1em 0' }} size="large" backgroundColor="blue" onClick={handleSubmit(onSubmit)}>
                            {i18n.t('CreateSession.button.create')}
                        </SignEvalButton>
                    )}
                </Box>
            </Drawer>
        </>
    );
};

export default DrawerCreateSession;
