import React from 'react';
import { useTheme } from '../hooks/useTheme';
import i18n from '../i18n/i18n';

// Components
import HeaderBar from '../components/HeaderBar';
import ContentInfo from '../components/ContentInfo';

// MUI imports
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import GestureOutlinedIcon from '@mui/icons-material/GestureOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';

const SessionSkeleton = () => {
    const theme = useTheme();
    const colorCountSigned = theme.palette.text.custom;

    const smallScreen = useMediaQuery('(max-height:700px)');

    return (
        <Box sx={{ width: '100%', height: '100vh', alignItems: 'center', backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
            <HeaderBar />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 auto',
                    width: '100%',
                    height: '92.5%'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '92.5%',
                        margin: '0 auto'
                    }}
                >
                    <ContentInfo margin="0.5em 0 0.3em 0" padding="0.5em 0">
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '92.5%',
                                height: smallScreen ? '21vh' : '15vh'
                            }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: 'auto', height: '100%' }}>
                                <Skeleton variant="text" width={200} height={30} sx={{ padding: '1.5em 0', marginBottom: '0.5em' }} />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-evenly',
                                        width: 'auto',
                                        color: theme.palette.text.primary
                                    }}
                                >
                                    <Skeleton variant="text" width={150} height={20} />
                                    <Skeleton variant="text" width={150} height={20} />
                                    <Skeleton variant="text" width={100} height={20} />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: 'auto', height: '100%' }}>
                                <Skeleton variant="rectangular" width={70} height={70} />
                                <Skeleton variant="text" width={70} height={30} />
                            </Box>
                        </Box>
                    </ContentInfo>
                    {/* <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', marginTop: '0.5em' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', color: '#FFB664' }}>
                            <PersonOffOutlinedIcon sx={{ marginRight: '0.2em', fontSize: '1.75em' }} />
                            <Skeleton variant="text" width={30} height={30} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', color: colorCountSigned }}>
                            <GestureOutlinedIcon sx={{ marginRight: '0.2em', fontSize: '1.75em' }} />
                            <Skeleton variant="text" width={50} height={30} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', color: '#5BB3D9' }}>
                            <InventoryOutlinedIcon sx={{ marginRight: '0.2em', fontSize: '1.75em' }} />
                            <Skeleton variant="text" width={50} height={30} />
                        </Box>
                    </Box> */}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '100%' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            width: '92.5%',
                            height: '45%',
                            maxHeight: '24vh',
                            marginTop: '0.25em'
                        }}
                    >
                        <Skeleton variant="rectangular" width="100%" height={50} sx={{ marginTop: '0.5em', borderRadius: '10px' }} />
                        <Skeleton variant="rectangular" width="100%" height={50} sx={{ marginTop: '0.5em', borderRadius: '10px' }} />
                        <Skeleton variant="rectangular" width="100%" height={50} sx={{ marginTop: '0.5em', borderRadius: '10px' }} />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '92.5%',
                            marginBottom: '3em'
                        }}
                    >
                        <Skeleton variant="rectangular" width="45%" height={40} sx={{ borderRadius: '10px' }} />
                        {/* <Skeleton variant="rectangular" width="45%" height={40} sx={{ borderRadius: '10px' }} /> */}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default SessionSkeleton;
