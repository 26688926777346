import { InternData } from '../../types/internData';
import { requestHeaders } from './requestHeaders';

export const getInternById = async (id: string): Promise<InternData | null> => {
    try {
        const response = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/users?id=' + id, {
            method: 'GET',
            headers: requestHeaders()
        });
        const data = (await response.json()).data[0];

        return {
            id: data.id,
            firstname: data.firstname,
            lastname: data.lastname,
            isSigned: false
        };
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const getInternByInfo = async (firstname: string, lastname: string, societyId: string): Promise<InternData | null> => {
    try {
        const response = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/users?firstname=' + firstname + '&lastname=' + lastname + '&societyId=' + societyId, {
            method: 'GET',
            headers: requestHeaders()
        });
        const data = (await response.json()).data[0];

        return {
            id: data.id,
            firstname: data.firstname,
            lastname: data.lastname,
            isSigned: false
        };
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const createIntern = async (civility: string, firstname: string, lastname: string, societyId: string, email?: string, registrationNumber?: string): Promise<{ status: number; id?: string }> => {
    try {
        const response = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/users', {
            method: 'POST',
            headers: requestHeaders(),
            body: JSON.stringify({
                civility: civility,
                firstname: firstname,
                lastname: lastname,
                email: email,
                registrationNumber: registrationNumber,
                profile: 'Stagiaire',
                active: false,
                societyId: societyId
            })
        });

        const data = await response.json();
        return { status: response.status, id: data.id };
    } catch (error) {
        console.error(error);
        return { status: 500 };
    }
};
