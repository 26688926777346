import i18n from '../../i18n/i18n';
import { TrainingData } from '../../types/trainingData';

export const formatTraining = (trainings: TrainingData[]) => {
    const sanitizedTrainings = trainings
        .filter((training) => training.trainingCode !== null)
        .map((training) => ({
            ...training,
            label: training.label === null ? i18n.t('CreateSession.trainingEmpty') : training.label
        }));

    const trainingMap = new Map();

    sanitizedTrainings.forEach((training) => {
        const existing = trainingMap.get(training.label);
        if (existing) {
            existing.trainingCode += `, ${training.trainingCode}`;
            existing.isDuplicate = true;
        } else {
            trainingMap.set(training.label, { ...training, isDuplicate: false });
        }
    });

    const formattedTrainings = Array.from(trainingMap.values()).map((training) => ({
        ...training,
        label: training.isDuplicate ? `${training.label} [${training.trainingCode}]` : training.label
    }));

    return formattedTrainings;
};
