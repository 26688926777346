import { format, isWithinInterval, parseISO, isAfter } from 'date-fns';
import { fr } from 'date-fns/locale';

// Types
import { SlotData } from '../../types/slotData';

// Helpers
import { getSlotByDate } from '../api/callSlot';

export const getCurrentSlot = async (sessionId: string): Promise<SlotData | undefined> => {
    const startAfter = format(new Date(), 'yyyy-MM-dd') + 'T00:00:00+01:00';
    const endBefore = format(new Date(), 'yyyy-MM-dd') + 'T23:59:59+01:00';

    const todaySlots = await getSlotByDate(encodeURIComponent(startAfter), encodeURIComponent(endBefore), sessionId);

    if (!todaySlots || todaySlots.length === 0) {
        console.error('No slots found');
        return undefined;
    }

    const currentSlot = todaySlots.find((slot) => {
        const startDate = parseISO(slot.startDate);
        const endDate = parseISO(slot.endDate);
        return isWithinInterval(new Date(), { start: startDate, end: endDate });
    });

    if (currentSlot) {
        return formatSlot(currentSlot);
    }

    const nextSlot = todaySlots
        .filter((slot) => {
            const startDate = parseISO(slot.startDate);
            return isAfter(startDate, new Date());
        })
        .sort((a, b) => parseISO(a.startDate).getTime() - parseISO(b.startDate).getTime());

    if (nextSlot.length > 0) {
        return formatSlot(nextSlot[0]);
    }

    console.error('No available slots left for today.');
    return undefined;
};

const formatSlot = (slot: SlotData): SlotData => {
    const formattedDate = format(new Date(slot.startDate), 'EEEE d MMMM', { locale: fr });
    const formattedStartTime = format(new Date(slot.startDate), 'HH:mm');
    const formattedEndTime = format(new Date(slot.endDate), 'HH:mm');

    return {
        id: slot.id,
        date: formattedDate,
        startDate: formattedStartTime,
        endDate: formattedEndTime,
        sessionId: slot.sessionId
    };
};
