import { format } from 'date-fns';
import { SessionData } from '../../types/sessionData';
import { requestHeaders } from './requestHeaders';
import { StatusSession } from '../../types/enumStatusSession';

export const getSessions = async (trainerId: string): Promise<Array<SessionData>> => {
    try {
        const sessionResponse = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/sessions?trainerId=' + trainerId, {
            method: 'GET',
            headers: requestHeaders()
        });
        const rawSessions = (await sessionResponse.json()).data;

        const responseTrainings = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/trainings', {
            method: 'GET',
            headers: requestHeaders()
        });
        const rawTrainings = (await responseTrainings.json()).data;
        const trainingMap = new Map(rawTrainings.map((training: any) => [training.id, training]));

        const sessions = rawSessions.map((session: any) => {
            const trainingInfo: any = trainingMap.get(Number(session.trainingId));
            const beginDate = format(new Date(session.startDate), 'dd/MM/yyyy');
            const endDate = format(new Date(session.endDate), 'dd/MM/yyyy');

            return {
                id: session.id,
                trainingLabel: trainingInfo ? trainingInfo.label : 'Pas de formation',
                sessionName: session.name,
                sessionBegin: beginDate,
                sessionEnd: endDate,
                trainees: session.traineesId,
                sessionToken: session.token
            };
        });
        return sessions;
    } catch (error) {
        console.error('Error fetching sessions:', error);
        return [];
    }
};

export const getSession = async (sessionId: string | undefined): Promise<SessionData | null> => {
    if (!sessionId) {
        console.error('sessionId is null or undefined');
        return null;
    }

    try {
        const responseSession = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/sessions?id=' + sessionId, {
            method: 'GET',
            headers: requestHeaders()
        });
        const dataSession = (await responseSession.json()).data[0];

        const responseTraining = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/trainings?id=' + dataSession.trainingId, {
            method: 'GET',
            headers: requestHeaders()
        });
        const dataTraining = (await responseTraining.json()).data[0];

        const beginDate = format(new Date(dataSession.startDate), 'dd/MM/yyyy');
        const endDate = format(new Date(dataSession.endDate), 'dd/MM/yyyy');

        const responseTrainer = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/users?id=' + dataSession.trainerId, {
            method: 'GET',
            headers: requestHeaders()
        });
        const dataTrainer = (await responseTrainer.json()).data[0];

        return {
            id: dataSession.id,
            trainingLabel: dataTraining.label,
            sessionToken: dataSession.token,
            sessionName: dataSession.name,
            sessionBegin: beginDate,
            sessionEnd: endDate,
            trainerId: dataSession.trainerId,
            trainerName: dataTrainer.firstname + ' ' + dataTrainer.lastname,
            trainees: dataSession.traineesId,
            slotsId: dataSession.slotsId
        };
    } catch (error) {
        console.error('Error getting session:', error);
        return null;
    }
};

export const getSessionByName = async (sessionName: string | undefined): Promise<string | null> => {
    if (!sessionName) {
        console.error('sessionName is null or undefined');
        return null;
    }

    try {
        const response = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/sessions?name=' + sessionName, {
            method: 'GET',
            headers: requestHeaders()
        });
        const data = (await response.json()).data[0];

        return data.id;
    } catch (error) {
        console.error('Error getting session by name:', error);
        return null;
    }
};

export const createSession = async (sessionName: string, trainingId: string | undefined, sessionBegin: string, sessionEnd: string, trainerId: string | undefined): Promise<{ status: number }> => {
    try {
        const response = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/sessions', {
            method: 'POST',
            headers: requestHeaders(),
            body: JSON.stringify({
                name: sessionName,
                trainingId: trainingId as string,
                startDate: sessionBegin,
                endDate: sessionEnd,
                trainerId: trainerId,
                status: StatusSession.CONFIRMED
            })
        });

        return { status: response.status };
    } catch (error) {
        console.error('Error creating session:', error);
        return { status: 500 };
    }
};

export const updateTraineesSession = async (sessionId: string, traineesId: Array<string>): Promise<{ status: number }> => {
    try {
        const response = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/sessions/' + sessionId, {
            method: 'PUT',
            headers: requestHeaders(),
            body: JSON.stringify({
                traineesIds: traineesId
            })
        });

        return { status: response.status };
    } catch (error) {
        console.error('Error updating trainees session:', error);
        return { status: 500 };
    }
};

export const deleteSession = async (sessionsId: string | undefined): Promise<boolean> => {
    if (!sessionsId) {
        console.error('sessionsId is null or undefined');
        return false;
    }

    try {
        const response = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/sessions/' + sessionsId, {
            method: 'DELETE',
            headers: requestHeaders()
        });

        if (response.status === 204) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error deleting session:', error);
        return false;
    }
};
