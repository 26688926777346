import React from 'react';
import { useTheme } from '../hooks/useTheme';
import i18n from '../i18n/i18n';

// Components
import HeaderBar from './HeaderBar';
import ContentInfo from './ContentInfo';

// MUI imports
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import GestureOutlinedIcon from '@mui/icons-material/GestureOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';

type InternsSkeletonProps = {
    nbInterns: number;
};

const InternsSkeleton = ({ nbInterns }: InternsSkeletonProps) => {
    const theme = useTheme();
    const colorCountSigned = theme.palette.text.custom;

    const internsListSkeleton = Array.from({ length: nbInterns }).map((_, index) => (
        <Box
            key={index}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                padding: '7.5px 0',
                color: theme.palette.secondary.main,
                backgroundColor: theme.palette.background.default,
                borderTop: '1px solid #EFEFEF'
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Skeleton variant="rectangular" width={50} height={50} sx={{ borderRadius: '50%' }} />
                <Skeleton variant="text" width={70} height={45} sx={{ margin: '0 10px' }} />
                <Skeleton variant="text" width={70} height={45} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Skeleton variant="rectangular" width={50} height={50} />
                <Skeleton variant="rectangular" width={50} height={50} sx={{ margin: '0 17.5px' }} />
            </Box>
        </Box>
    ));

    return (
        <Box sx={{ width: '100%', height: '100vh', alignItems: 'center', backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
            <HeaderBar />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 auto',
                    width: '100%'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '92.5%',
                        margin: '0 auto'
                    }}
                >
                    <ContentInfo margin="0.5em 0 0.3em 0" padding="0.5em 0 0.25em 0">
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '92.5%',
                                minHeight: '10vh'
                            }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: 'auto', height: '100%' }}>
                                <Skeleton variant="text" width={200} height={50} sx={{ margin: '1em 0' }} />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: 'auto',
                                        color: theme.palette.text.primary
                                    }}
                                >
                                    <Skeleton variant="text" width={90} height={20} sx={{ fontSize: '0.8em', textTransform: 'capitalize' }} />
                                    <Skeleton variant="text" width={70} height={20} sx={{ fontSize: '0.8em', marginLeft: '0.5em', fontWeight: '600' }} />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Skeleton variant="rounded" width={70} height={70} />
                                <Skeleton variant="text" width={70} height={40} />
                            </Box>
                        </Box>
                    </ContentInfo>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '6vh', marginBottom: '0.5em' }}>
                        {/* <Box sx={{ display: 'flex', flexDirection: 'row', color: '#FFB664' }}>
                            <PersonOffOutlinedIcon sx={{ marginRight: '0.2em', fontSize: '1.75em' }} />
                            <Skeleton variant="text" width={30} height={30} />
                        </Box> */}
                        <Box sx={{ display: 'flex', flexDirection: 'row', color: colorCountSigned }}>
                            <GestureOutlinedIcon sx={{ marginRight: '0.2em', fontSize: '1.75em' }} />
                            <Skeleton variant="text" width={50} height={30} />
                        </Box>
                        {/* <Box sx={{ display: 'flex', flexDirection: 'row', color: '#5BB3D9' }}>
                            <InventoryOutlinedIcon sx={{ marginRight: '0.2em', fontSize: '1.75em' }} />
                            <Skeleton variant="text" width={50} height={30} />
                        </Box> */}
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '96%', marginLeft: 'auto' }}>
                    <Typography sx={{ fontWeight: '600', fontSize: '1.2em', marginBottom: '0.5em', color: theme.palette.primary.main }}>{i18n.t('Interns.title')}</Typography>
                    {internsListSkeleton}
                </Box>
            </Box>
        </Box>
    );
};

export default InternsSkeleton;
