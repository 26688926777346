import { useEffect, useState } from 'react';
import { useTheme } from '../hooks/useTheme';
import { Link } from 'react-router-dom';
import i18n from '../i18n/i18n';

// MUI imports
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import GestureOutlinedIcon from '@mui/icons-material/GestureOutlined';
import CircularProgress from '@mui/material/CircularProgress';

// Helpers
import { getAttendances } from '../helpers/api/callAttendance';

// Types
import { SlotData } from '../types/slotData';

interface SignButtonProps {
    route: string;
    internId: string;
    sessionId: string | undefined;
    todaySlot: SlotData;
}

const SignButton = ({ route, internId, sessionId, todaySlot }: SignButtonProps) => {
    const theme = useTheme();
    const [isSigned, setIsSigned] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const bgColor = isSigned ? '#36D476' : theme.palette.text.custom;

    const fetchAttendances = async () => {
        setIsLoading(true);
        if (!sessionId || sessionId === '') return;

        const isSigned = await getAttendances(sessionId, internId, todaySlot.id);
        setIsSigned(isSigned);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchAttendances();
    }, [sessionId]);

    return (
        <a href={route} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', width: '100%' }}>
            <IconButton
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '0%',
                    backgroundColor: isLoading ? theme.palette.text.custom : bgColor,
                    color: theme.palette.text.secondary,
                    width: '2.25em',
                    height: '2.25em',
                    marginRight: '17.5px'
                }}
            >
                {isLoading ? (
                    <CircularProgress sx={{ color: theme.palette.mode === 'light' ? '#FFFFFF' : '#000000' }} size={20} />
                ) : (
                    <>
                        <GestureOutlinedIcon />
                        <Typography sx={{ fontSize: '10px', color: theme.palette.text.secondary }}>{i18n.t('SignButton.sign')}</Typography>
                    </>
                )}
            </IconButton>
        </a>
    );
};

export default SignButton;
