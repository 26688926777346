import { SlotData } from '../../types/slotData';
import { requestHeaders } from './requestHeaders';

export const getSlotById = async (slotId: string): Promise<SlotData | null> => {
    try {
        const response = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/slots?id=' + slotId, {
            method: 'GET',
            headers: requestHeaders()
        });
        const data = (await response.json()).data[0];

        return {
            id: data.id,
            startDate: data.startDate,
            endDate: data.endDate
        };
    } catch (error) {
        console.error('Error getting slot:', error);
        return null;
    }
};

export const getSlotByDate = async (startAfter: string, endBefore: string, sessionId: string): Promise<Array<SlotData> | null> => {
    try {
        const response = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/slots?startAfter=' + startAfter + '&endBefore=' + endBefore + '&sessionId=' + sessionId, {
            method: 'GET',
            headers: requestHeaders()
        });
        const data = (await response.json()).data;

        return data.map((slot: SlotData) => ({
            id: slot.id,
            startDate: slot.startDate,
            endDate: slot.endDate
        }));
    } catch (error) {
        console.error('Error getting slot:', error);
        return null;
    }
};
