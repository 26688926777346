import { SessionData } from '../../types/sessionData';

export const sortAscendingSession = (sessions: SessionData[]): SessionData[] => {
    const now = new Date();

    const upcomingSoon: SessionData[] = [];
    const ongoing: SessionData[] = [];
    const upcoming: SessionData[] = [];
    const past: SessionData[] = [];

    sessions.forEach((session) => {
        if (session.sessionBegin && session.sessionEnd) {
            const sessionStart = new Date(session.sessionBegin.split('/').reverse().join('-'));
            const sessionEnd = session.sessionEnd ? new Date(session.sessionEnd.split('/').reverse().join('-')) : null;

            if (sessionStart > now && sessionStart.getTime() - now.getTime() <= 7 * 24 * 60 * 60 * 1000) {
                upcomingSoon.push(session);
            } else if (now >= sessionStart && sessionEnd && now <= sessionEnd) {
                ongoing.push(session);
            } else if (sessionStart > now) {
                upcoming.push(session);
            } else {
                past.push(session);
            }
        }
    });

    return [
        ...ongoing.sort((a, b) => {
            const sessionBeginA = a.sessionBegin ? new Date(a.sessionBegin.split('/').reverse().join('-')) : null;
            const sessionBeginB = b.sessionBegin ? new Date(b.sessionBegin.split('/').reverse().join('-')) : null;
            return sessionBeginA && sessionBeginB ? sessionBeginA.getTime() - sessionBeginB.getTime() : 0;
        }),
        ...upcomingSoon.sort((a, b) => {
            const sessionBeginA = a.sessionBegin ? new Date(a.sessionBegin.split('/').reverse().join('-')) : null;
            const sessionBeginB = b.sessionBegin ? new Date(b.sessionBegin.split('/').reverse().join('-')) : null;
            return sessionBeginA && sessionBeginB ? sessionBeginA.getTime() - sessionBeginB.getTime() : 0;
        }),
        ...upcoming.sort((a, b) => {
            const sessionBeginA = a.sessionBegin ? new Date(a.sessionBegin.split('/').reverse().join('-')) : null;
            const sessionBeginB = b.sessionBegin ? new Date(b.sessionBegin.split('/').reverse().join('-')) : null;
            return sessionBeginA && sessionBeginB ? sessionBeginA.getTime() - sessionBeginB.getTime() : 0;
        }),
        ...past.sort((a, b) => {
            const sessionEndA = a.sessionEnd ? new Date(a.sessionEnd.split('/').reverse().join('-')) : null;
            const sessionEndB = b.sessionEnd ? new Date(b.sessionEnd.split('/').reverse().join('-')) : null;
            return sessionEndA && sessionEndB ? sessionEndB.getTime() - sessionEndA.getTime() : 0;
        })
    ];
};
