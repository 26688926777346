import { create } from 'zustand';
import { SessionData } from '../types/sessionData';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';

// API
import { getSession } from '../helpers/api/callSession';

type SessionStoreType = {
    session: SessionData;
    loading: boolean;
    setSession: (session: SessionData) => void;
    clearSession: () => void;
    setLoading: (isLoading: boolean) => void;
};

const initialSession: SessionData = {
    id: '',
    trainingLabel: '',
    sessionToken: '',
    sessionName: '',
    sessionBegin: '',
    sessionEnd: '',
    trainerId: '',
    trainerName: '',
    trainingId: '',
    trainees: [],
    slotsId: []
};

export const useSessionStore = create<SessionStoreType>()(
    devtools(
        persist(
            (set, get) => ({
                session: initialSession,
                loading: false,
                setSession: async (session: SessionData) => {
                    const temporarySession = { ...get().session, ...session };
                    if (session.id) {
                        set({ loading: true });
                        try {
                            const completedSession = await getSession(session.id);
                            set({ session: { ...temporarySession, ...completedSession } });
                        } finally {
                            set({ loading: false });
                        }
                    } else {
                        set({ session: temporarySession });
                    }
                },
                setLoading: (isLoading: boolean) => set({ loading: isLoading }),
                clearSession: () => set({ session: initialSession, loading: false })
            }),
            {
                name: 'session',
                storage: createJSONStorage(() => sessionStorage)
            }
        )
    )
);
